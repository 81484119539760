import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Seo } from "../components/";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Speaking" keywords={[`conferences`, `talks`]} mdxType="Seo" />
    <h1 {...{
      "id": "speaking"
    }}>{`Speaking`}</h1>
    <p>{`Links to slide decks and recordings of talks.`}</p>
    <h2 {...{
      "id": "a-deep-dive-into-gatsby-image"
    }}>{`A Deep Dive into Gatsby Image`}</h2>
    <p><em parentName="p">{`Presented at `}<a parentName="em" {...{
          "href": "https://www.gatsbyjs.com/resources/gatsby-days/"
        }}>{`Gatsby Days NYC`}</a>{` on 5.6.2019.`}</em></p>
    <p><strong parentName="p">{`Summary`}</strong>{`: A deep dive into the "Gatsby Image" component and how it works.`}</p>
    <ul>
      <li parentName="ul">{`📹 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=0a5kmU0Dr80"
        }}>{`Video`}</a></li>
    </ul>
    <h2 {...{
      "id": "whats-your-jamstack"
    }}>{`What's Your JAMstack?`}</h2>
    <p><em parentName="p">{`Presented at `}<a parentName="em" {...{
          "href": "https://jamstackconf.com/nyc/"
        }}>{`JAMstack Conf NYC`}</a>{` on 4.9.2019.`}</em></p>
    <p><strong parentName="p">{`Summary:`}</strong>{` Shifting from a monolithic CMS approach to a JAMstack setup comes with an abundance of choice. On the other hand, all that choice can come with indecision paralysis. Where’s a dev to start? Thankfully, there’s a thriving ecosystem of frameworks offering varying flexibility and structure, different approaches to performance and delivery, etc. We’ll talk about some ways to evaluate available tools, using Gatsby as an example.`}</p>
    <ul>
      <li parentName="ul">{`⚡️ `}<a parentName="li" {...{
          "href": "https://whats-your-jamstack.amberley.io/"
        }}>{`Live slides`}</a>{` (⚠️ 6.5 MB)`}</li>
      <li parentName="ul">{`🔗 `}<a parentName="li" {...{
          "href": "https://speakerdeck.com/amberleyromo/whats-your-jam-stack"
        }}>{`Speaker deck`}</a>{` (⚠️ 1.3 MB)`}</li>
      <li parentName="ul">{`📹 `}<a parentName="li" {...{
          "href": "https://youtu.be/WVoh9uNOUMk"
        }}>{`Video`}</a></li>
    </ul>
    <h2 {...{
      "id": "making-modern-web-performance-accessible"
    }}>{`Making Modern Web Performance Accessible`}</h2>
    <p><em parentName="p">{`Presented at `}<a parentName="em" {...{
          "href": "https://fitc.ca/event/perf/"
        }}>{`FITC Spotlight: Web Performance`}</a>{` on 3.23.2019.`}</em></p>
    <p><strong parentName="p">{`Summary:`}</strong>{` Performance is critical. Yet it often feels like developers are forced to choose between powerful JavaScript frameworks with significant performance drawbacks or high-performance apps that require a huge amount of custom coding. For the average developer, this can be overwhelming. We operate with time and budget constraints. “I want to care about performance, but where do I start? How do I prioritize it?”`}</p>
    <p>{`This talk will overview the performance challenges of building in the modern web landscape, current approaches to tackling those challenges, and how we can lower the barrier to entry to make these techniques more accessible.`}</p>
    <ul>
      <li parentName="ul">{`⚡️ `}<a parentName="li" {...{
          "href": "https://making-web-perf-accessible.amberley.io/#0"
        }}>{`Live slides`}</a>{` (⚠️ 6.1 MB)`}</li>
      <li parentName="ul">{`📹 `}<a parentName="li" {...{
          "href": "https://youtu.be/moGLzw6Wu00"
        }}>{`Video`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      